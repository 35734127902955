.btnNav {
    box-shadow: none;
    border: solid 1px;
    padding: 10px;
    margin-left: 25px
}

.nameNavbar {
    font-size: 25px;
    padding-left: 15px;
}

.navLogo {
    width: 75px;
    height: 50px;
    margin-right: 15px;

}


.labelCab {
    /* vertical-align: bottom;
    display: inline-block; */
    /* position: absolute; */
    /* добавили */
    /* bottom: 0;
    left: 100px; */
    /* добавили */
    color: white;

}

.labelCab h5 {
    font-size: 20px;
}

.label {
    font-size: 19px;
}