.btnCabinet {
    padding: 20px;
    font-size: 15px;

}


.fa-edit {
    padding-right: 10px;
}

.btnNav {
    box-shadow: none;
    border: solid 1px;
    padding: 10px;
    margin-left: 25px
}