.text-center {
    box-sizing: border-box;
}


.cabinetAll {
    padding-top: 20px;
}

/* .stat {
    padding-left: 0px;
    background-color: rgb(128, 165, 42);
}

.stat ul {
    background-color: brown;
}

.grupRez {

    background-color: chocolate;
} */